<template>

  <div>

    <div
      class="infopane"
      :title="`${win_w}px/${win_h}px`"
    >

      <b>{{ratio | round(2)}}</b>
    </div>

  </div>

</template>


<script>
// const R = require("ramda");

export default {
  name: "InfoPane",
  data: function() {
    return {
      win_h: 0,
      win_w: 0
    };
  },
  computed: {
    ratio: function() {
      return this.win_w / this.win_h;
    }
  },
  methods: {
    updateWindowSize: function() {
      this.win_h = window.innerHeight;
      this.win_w = window.innerWidth;
    }
  },
  mounted: function() {
    window.addEventListener("resize", this.updateWindowSize);
    this.updateWindowSize();
  }
};
</script>

<style lang="scss">
@import "../styles/settings.scss";
.infopane {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 2000;
  border: 1px solid red;
  background: rgba(white, 1);
  padding: 1em;
}
</style>

