<template>

  <div>

    <div id="panelnav">
      <span
        v-for="(l, index) in panelLinks"
        :key="index"
        @mouseover="scrollTo(l)"
        :class="{active: (l.id==curId)}"
      >{{index + 1 | pad}}</span>

    </div>

  </div>

</template>


<script>
const R = require("ramda");

import Vue from "vue";

var debounce = require("lodash.debounce");

Vue.filter("pad", (number, padding = 2) =>
  number.toString().padStart(padding, "0")
);

var panels = null,
  panelsnap = null;

export default {
  name: "PanelNav",
  data: function() {
    return {
      panelLinks: [],
      curId: null,
      panelscontainer: null
    };
  },
  props: {
    speed: { type: String, default: "1000" },
    easing: { type: String, default: "easeOutCubic" }
  },
  computed: {
    ratio: function() {
      return this.win_w / this.win_h;
    }
  },
  methods: {
    setupPanels: function() {
      this.panelscontainer = document.querySelector(".panels");

      panels = document.querySelectorAll(".panels > .ce");
      this.panelLinks = R.pipe(
        R.map(panel => {
          // console.log(`#log 3210`,panel.offsetTop);
          return { id: panel.id, el: panel };
        })
      )(panels);
      this.curId = this.panelLinks[0].id;
    },
    showNextPanel: debounce(
      function(direction) {
        if (panelsnap) {
          console.log("#SCROLL ", direction);
          const nextidx = this.getCurrentPanelIndex() + direction;
          console.log("showNextPanel", direction, nextidx);
          if (nextidx >= 0 && nextidx < this.panelLinks.length) {
            this.scrollTo(this.panelLinks[nextidx]);
          }
        }
      },
      50,
      {
        leading: true,
        trailing: false
      }
    ),
    getCurrentPanelIndex: function() {
      return this.panelLinks.findIndex(l => l.id == this.curId);
    },
    scrollTo: function(panelLink) {
      console.log("#log 4466 scrollTo", panelLink);
      this.scrollToPos(panelLink.el.offsetTop);
      this.curId = panelLink.id;
      // panelLink.el.scrollIntoView({
      //   behavior: "smooth",
      //   block: "center"
      // });
    },
    scrollToPos: function(y) {
      this.panelscontainer.style.transform = `translate3d(0, -${y}px, 0px)`;
    },
    onResize: debounce(function() {
      var mq = window.matchMedia(
        "(max-aspect-ratio: 22/10) and (min-aspect-ratio: 12/10)"
      );
      panelsnap = mq.matches ? true : false;
      console.log("#log 7489 RESIZED", panelsnap);
    }, 500)
  },
  mounted: function() {
    this.setupPanels();
    window.addEventListener("resize", this.onResize);
    window.addEventListener("wheel", event => {
      const delta = Math.sign(event.deltaY);
      this.showNextPanel(delta);
    });

    var ts;
    window.addEventListener("touchstart", e => {
      ts = e.touches[0].clientY;
    });

    window.addEventListener("touchend", e => {
      var te = e.changedTouches[0].clientY;
      if (ts > te + 5) {
        this.showNextPanel(+1);
      } else if (ts < te - 5) {
        this.showNextPanel(-1);
      }
    });
    this.onResize();
  }
};
</script>

