import Vue from "vue";
import VueRouter from "vue-router";
import InfoPane from "../components/InfoPane";
import PanelNav from "../components/PanelNav";
import ShareBox from "../components/ShareBox";
const make_router = function (widgetProps) {
  return new VueRouter({
    linkActiveClass: "is-active",
    mode: "abstract",
    routes: [{
        path: "/infopane",
        component: InfoPane,
        name: "frontend-infopane",
        props: widgetProps
      },

      {
        path: "/panelnav",
        component: PanelNav,
        name: "frontend-panelnav",
        props: widgetProps
      },

      {
        path: "/sharebox",
        component: ShareBox,
        name: "frontend-sharebox",
        props: widgetProps
      },

    ]
  });
};

export default make_router;
