<template>

  <div>

    <div
      id="sharebox"
      :class="{'is-open':isOpen}"
    >

      <template v-if="isOpen">
        <div
          class="icon"
          @click="shareMail()"
          title="share via e-Mail"
        >
          <i class='icon icon-mail-icon'></i>
        </div>
        <div
          class="icon"
          @click="shareWhatsapp()"
          title="share on Whatsapp"
        >
          <i class='icon icon-whatsapp-icon'></i>
        </div>
        <div
          class="icon"
          @click="sharePinterest()"
          title="share on pinterest"
        >
          <i class='icon icon-pinterest-icon'></i>
        </div>
        <div
          class="icon"
          @click="shareTwitter()"
          title="share on twitter"
        >
          <i class='icon icon-twitter-icon'></i>
        </div>
        <div
          class="icon"
          @click="shareFacebook()"
          title="share on facebook"
        >
          <i class='icon icon-facebook-icon'></i>
        </div>
        <div class="icon close">
          <i
            class='icon icon-close-icon'
            @click="close()"
          ></i>
        </div>
      </template>
      <template v-else>
        <div
          class="icon open"
          @click="isOpen=true"
        >
          <i class='icon icon-teilen-icon'></i>
        </div>
      </template>
    </div>

  </div>

</template>


<script>
const R = require("ramda");

import Vue from "vue";

export default {
  name: "ShareBox",
  data: function() {
    return {
      isOpen: false,
      title: "n/a",
      image: null,
      url: "n/a"
    };
  },
  props: {},
  computed: {},
  components: {
    // TeilenIcon,
    // TwitterIcon,
    // FacebookIcon,
    // MailIcon,
    // CloseIcon
  },
  methods: {
    shareFacebook: function() {
      const url = `https://www.facebook.com/sharer.php?u=${this.url}`;
      window.open(url, "_blank");
    },
    shareTwitter: function() {
      const url = `https://twitter.com/share?url=${this.url}`;
      window.open(url, "_blank");
    },
    sharePinterest: function() {
      const url =
        `https://pinterest.com/pin/create/bookmarklet/?media=${this.image}&url=${this.url}&description=` +
        escape(this.title);
      window.open(url, "_blank");
    },
    shareWhatsapp: function() {
      const url = "https://wa.me/?text=" + escape(this.title + " " + this.url);
      window.open(url, "_blank");
    },
    shareMail: function() {
      const subject = `${this.title}`;
      const body = `Check out this site: ${this.title} ${this.url}`;
      const url =
        "mailto:?subject=" + escape(subject) + "&body=" + escape(body);
      window.open(url, "_blank");
    },
    close: function() {
      this.isOpen = false;
    }
  },
  mounted: function() {
    this.title = jQuery("meta[property='og:title']").attr("content");
    this.url = jQuery("meta[property='og:url']").attr("content");
    this.image = jQuery("meta[property='og:image']").attr("content");
  }
};
</script>

<style lang="scss">
@import "../styles/settings.scss";
</style>

